.select-season {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: 3rem;
    color: #fff;
    position: relative;
    z-index: 1;
}

.select-season__button {
    width: 100%;
    position: absolute;
    font-family: -apple-system, 'Grandstander', sans-serif;
    font-size: 1rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    background-color: darken($color: $color-dark-blue, $amount: 20%);
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    cursor: pointer;
    color: #fff;
    z-index: 1;
    transition: all ease 0.4s;
}

.select-season__button:hover{
    background-color: darken($color: $color-dark-blue, $amount: 10%);
    color: $color-yellow;
}
.select-season__button:hover .select-sesion-icon{
    border-left: 2px solid $color-yellow;
    border-bottom: 2px solid $color-yellow;
}

.select-sesion-icon {
    width: 15px;
    height: 15px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg) translateY(-40%);
    z-index: -1;
    transition: all ease 0.4s;
}

.select-season__ul {
    position: absolute;
    top: 3rem;
    list-style: none;
    width: 100%;
    height: 40vh;
    overflow: auto;
    opacity: 0;
    transition: all ease 0.4s;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
}

.select-season__ul--visible {
    opacity: 1;
    pointer-events: all;
}

.select-season__ul--oculto {
    opacity: 0;
    transform: translateY(-45px);
    pointer-events: none;
}

.select-season__li {
    background-color: white;
    color: $color-dark-blue;
    font-weight: bold;                         
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    padding: 12px 20px;
    transition: all ease 0.4s;
}

.select-season__li:hover{
    background-color: $color-yellow;
}