.home{
    width: 100%;
    display: flex;
    min-height: calc(80vh - 30px);
    position: relative;
}

.home-title{
    width: 50%;
    font-size: 4rem;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 1;
}
.home-img{
    width: 50%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    img{
        width: 100%;
    }
}