.drivers{
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(80vh - 30px);
    margin-bottom: 20px;
}
.tableDriverStandings {
    width: 80%;
    max-width: 960px;
    margin-top: 10px;
    text-align: center;
    border-collapse: collapse;
    box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.5);
  }
  
  .tableDriverStandings th, td {
    /* border: 1px solid #fff; */
    padding: 5px 5px;
    color: white;
  }

  .tableDriverStandings th{
      color: $color-yellow;
  }
  
  .tableDriverStandings tr:nth-child(odd) {
    /* background-image: radial-gradient(circle at 50% 50%, #6ac395 0, #50ba90 25%, #29ae89 50%, #00a283 75%, #009980 100%); */
    // background: linear-gradient(270deg, rgba(11,143,171,0.5) 10%, rgba(39,73,74,0.7) 100%);
    // background-color: darken($color: $color-light-blue, $amount: 5%);
    background-color: $color-dark-blue;
  }
  
  .tableDriverStandings tr:nth-child(even) {
    /* background-image: linear-gradient(170deg, #549271 0, #3f896b 25%, #207e63 50%, #00735c 75%, #006a58 100%); */
    // background: linear-gradient(270deg, rgba(35,76,84,0.5) 10%, rgba(82,153,156,0.7) 100%); 

    background-color: darken($color: $color-dark-blue, $amount: 10%);
  }

  .msg-buscando{
    color: white;
  }