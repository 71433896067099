.nav{
    width: 100%;
    height: 10vh;
    min-height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0px;
}

.nav ul{
    display: flex;
    justify-content: space-around;
    width: 50%;
}

.nav-logo{
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
}

.nav-link{
    text-decoration: none;
    color: $color-yellow;
    text-align: center;
    font-weight: bold;
    background-color: darken($color: $color-dark-blue, $amount: 10%);
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: all 0.3s ease;

    &:hover{
        background-color: lighten($color: $color-dark-blue, $amount: 10%);
        color: lighten($color: $color-yellow, $amount: 10%);
    }
}