@media screen and (max-width: 1080px){

    .nav ul{
        width: 60%;
    }

    .home-title{
        font-size: 3rem;
    }
}

@media screen and (max-width: 1080px) and (orientation: portrait){
    .home{
        min-height: 50vh;
    }
}

@media screen and (max-width: 950px){
    .nav-link{
        font-size: 0.9rem;
        height: auto;
    }

    .home-title{
        font-size: 2rem;
    }
}

@media screen and (max-width: 600px){
    
    .nav{
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    .nav ul{
        width: 80%;
        align-items: center;
    }

    .nav-link{
        font-size: 0.8rem;
        margin: 0px 10px;
        min-width: 150px;
    }

    .select-season{
        width: 70%;
    }

    .row-wins{
        display: none;
    }
}